<template>
  <test-echop />
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { isInLab } from '@/config'

import testEchop from './test-echop'
// import testSf from './test-sf'

export default {
  components: {
    testEchop,
    // testSf,
  },
  computed: {
    // ====== vuex ======
    ...mapGetters('user', [
      'loginStatus'
    ]),
  },
  methods: {
    // ====== vuex ======
    ...mapActions('app', ['setMainTitle']),
  },
  created () {
    // check if is tester
    if (!this.loginStatus || !this.loginStatus.userInfo || !isInLab(this.loginStatus.userInfo.primaryEmail)) {
      this.$router.push('/')
    }
    // 设置顶部标题
    this.setMainTitle('Lab')
  },  
}
</script>

<style>

</style>
