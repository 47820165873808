<template>
  <v-layout wrap>
    <v-flex
      lg6
    >
      <material-app-card
        icon="mdi-toolbox-outline"
        title="Test Create"
      >
        <v-card-title>
          <v-btn @click="postCreate" color="info">
            Create
          </v-btn>
        </v-card-title>
      </material-app-card>
    </v-flex>
  </v-layout>  
</template>

<script>
import { mapGetters } from 'vuex'
import { xPost } from '@/functions/http/axios'

export default {
  computed: {
    ...mapGetters('user', [
      'loginStatus'
    ])
  },
  methods: {    
    async postCreate () {
      try {
        const res = await xPost(
          'mylab',
          'test_echop',
          {
            loginStatus: this.loginStatus
          }
        )
        if (res.data.status === 200) {
          // let data = res.data.data
          // console.log(data)
        } else {
          try {
            if (res.data.message === 'Authorize Failed') {
              this.$router.push('/login')
              // this.$emit('auth-fail')
              throw 'Authorize Failed'
            }
          } catch (e) {}
          throw {
            response: {
              status: res.data.status
            }
          }
        }
      } catch (err) {
        console.log('Error [test echop - create]: ')
        // console.log(err)
      }
    },
  }
}
</script>

<style>

</style>
